









import { Component, Vue } from 'vue-property-decorator'

import EventSubscriptionsItemsTable from '@/partials/tables/Events/Subscriptions/Items/index.vue'

@Component({
  components: {
    EventSubscriptionsItemsTable
  }
})
export default class SubscriptionItemsIndex extends Vue {

}
