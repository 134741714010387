





































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import isEmpty from 'lodash/isEmpty'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import TablePagination from '@/partials/tables/Pagination.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TablePagination,
    TrashCan16,
    Edit16,
    GenericModalDelete
  }
})
export default class EventSubscriptionsItemsTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) subscriptionId!: string
  totalItems = 0
  items: Array<any> = []
  filteredData: Array<any> = []
  deleteModalIsOpened = false
  selectedItem: any = {}

  fetch (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(`/event/${this.eventId}/subscription/type/${this.subscriptionId}/items`, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.items = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.totalItems = response.data.total
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Item foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  get deleteUrl () {
    return isEmpty(this.selectedItem) ? '' : `/event/${this.eventId}/subscription/type/${this.subscriptionId}/items/${this.selectedItem.id}`
  }

  onPageChange (pagination: Pagination) {
    this.fetch(pagination.page, pagination.length)
  }
}
